import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import MetisMenu from '@metismenu/react';
import * as IconsBi from "react-icons/bi";
import 'metismenujs/style';

function Menu(props) {
    const menuItems = [
        {
            id: 'dashboard',
            title: 'داشبورد',
            icon: <IconsBi.BiSlideshow />,
            url: '/dashboard',
            visibility: true,
            children: '',
        },
        {
            id: 'settings',
            title: 'تنظیمات',
            icon: <IconsBi.BiCog />,
            url: '#',
            visibility: true,
            children: [
                {
                    id: 'settings-base',
                    title: 'تنطیمات قالب',
                    icon: <IconsBi.BiCircle />,
                    url: '/settings/base',
                    visibility: true,
                },
                {
                    id: 'settings-slider',
                    title: 'تنطیمات اسلایدر',
                    icon: <IconsBi.BiCircle />,
                    url: '/settings/slider',
                    visibility: true,
                },
            ]
        },
        {
            id: 'users',
            title: 'کاربران',
            icon: <IconsBi.BiGroup />,
            url: '#',
            visibility: true,
            children: [
                {
                    id: 'users-new',
                    title: 'ایجاد کاربر جدید',
                    icon: <IconsBi.BiCircle />,
                    url: '/users/new',
                    visibility: true,
                },
                {
                    id: 'users-list',
                    title: 'لیست کاربران',
                    icon: <IconsBi.BiCircle />,
                    url: '/users/list',
                    visibility: true,
                },
            ]
        },
        {
            id: 'layouts',
            title: 'ترکیب بندی',
            icon: <IconsBi.BiLayout />,
            url: '#',
            visibility: true,
            children: [
                {
                    id: 'layouts-new',
                    title: 'ایجاد ترکیب بندی جدید',
                    icon: <IconsBi.BiCircle />,
                    url: '/layouts/new',
                    visibility: true,
                },
                {
                    id: 'layouts-list',
                    title: 'لیست ترکیب بندی ها',
                    icon: <IconsBi.BiCircle />,
                    url: '/layouts/list',
                    visibility: true,
                },
            ]
        },
        {
            id: 'categories',
            title: 'دسته بندی',
            icon: <IconsBi.BiCategoryAlt />,
            url: '#',
            visibility: true,
            children: [
                {
                    id: 'categories-new',
                    title: 'ایجاد دسته جدید',
                    icon: <IconsBi.BiCircle />,
                    url: '/categories/new',
                    visibility: true,
                },
                {
                    id: 'categories-list',
                    title: 'لیست دسته ها',
                    icon: <IconsBi.BiCircle />,
                    url: '/categories/list',
                    visibility: true,
                },
            ]
        },
        {
            id: 'articles',
            title: 'مطالب',
            icon: <IconsBi.BiNews />,
            url: '#',
            visibility: true,
            children: [
                {
                    id: 'articles-new',
                    title: 'ایجاد مطلب جدید',
                    icon: <IconsBi.BiCircle />,
                    url: '/articles/new',
                    visibility: true,
                },
                {
                    id: 'articles-list',
                    title: 'لیست مطالب',
                    icon: <IconsBi.BiCircle />,
                    url: '/articles/list',
                    visibility: true,
                },
            ]
        },
        {
            id: 'files',
            title: 'فایلها',
            icon: <IconsBi.BiArchive />,
            url: '#',
            visibility: true,
            children: [
                {
                    id: 'files-new',
                    title: 'ثبت فایل جدید',
                    icon: <IconsBi.BiCircle />,
                    url: '/files/new',
                    visibility: true,
                },
                {
                    id: 'files-list',
                    title: 'لیست فایلها',
                    icon: <IconsBi.BiCircle />,
                    url: '/files/list',
                    visibility: true,
                },
            ]
        },
        {
            id: 'tags',
            title: 'تگها',
            icon: <IconsBi.BiTagAlt />,
            url: '#',
            visibility: true,
            children: [
                {
                    id: 'tags-new',
                    title: 'ایجاد تگ جدید',
                    icon: <IconsBi.BiCircle />,
                    url: '/tags/new',
                    visibility: true,
                },
                {
                    id: 'tags-list',
                    title: 'لیست تگها',
                    icon: <IconsBi.BiCircle />,
                    url: '/tags/list',
                    visibility: true,
                },
            ]
        },
        {
            id: 'menu',
            title: 'منوها',
            icon: <IconsBi.BiMenuAltRight />,
            url: '#',
            visibility: true,
            children: [
                {
                    id: 'menu-header',
                    title: 'منوی سرصفحه',
                    icon: <IconsBi.BiCircle />,
                    url: '/menu/header',
                    visibility: true,
                },
                {
                    id: 'menu-footer',
                    title: 'منوی پاصفحه',
                    icon: <IconsBi.BiCircle />,
                    url: '/menu/footer',
                    visibility: true,
                },
            ]
        },
        {
            id: 'logout',
            title: 'خروج',
            icon: <IconsBi.BiExit />,
            url: '/logout',
            visibility: true,
        },
    ]

    return (
        <>
            <MetisMenu>
                {
                    menuItems.filter(mi => mi.visibility).map(mi => {
                        return (
                            <li key={mi.id}>
                                <NavLink 
                                    to={mi.url} 
                                    className={( mi.children?.length > 0 && mi.children?.filter(child=>child.visibility).length > 0) && "has-arrow"}
                                >
                                    <div className="parent-icon">
                                        {mi.icon}
                                    </div>
                                    <div className="menu-title">
                                        {mi.title}
                                    </div>
                                </NavLink>
                                {
                                    ( mi.children?.length > 0 && mi.children?.filter(child=>child.visibility).length > 0 ) &&
                                    <ul className="ms-2">
                                        {
                                            mi.children.filter(child => child.visibility).map(child => {
                                                return (
                                                    <li key={child.id}>
                                                        <NavLink to={child.url}>
                                                            <div className="child-icon">
                                                                {child.icon}
                                                            </div>
                                                            <div className="menu-title">
                                                                {child.title}
                                                            </div>    
                                                        </NavLink>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                }
                            </li>
                        )
                    })
                }
            </MetisMenu>
        </>
    )
}

export default Menu;