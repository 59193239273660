import React from 'react'

function Footer() {
    return (
        <footer className="page-footer">
            <p className="mb-0">طراحی و پیاده سازی: 
                <a href="http://www.greenapps.ir" target="_blank" style={{color:'#147c33'}}> Green Apps</a>
            </p>
        </footer>
    )
}

export default Footer;