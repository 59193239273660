import React, { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import ReactDOM from "react-dom";
import store from './../../store/store';
import { connect } from 'react-redux';
import { Axios, AxiosErrorHandling } from "./../../connection/Axios"
import { dictionary } from "../common/Config";
import { Form, Button, Col, Alert } from 'react-bootstrap';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import logo from './../../assets/images/login-images/login-cover.svg'
import logoIcon from './../../assets/images/logo-icon.png'



function Login(props) {
    const [serverErrors, setServerErrors] = useState(null);
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [logging, setLogging] = useState(false);

    const [hasError, setHasError] = useState(false)
    const [target, setTarget] = useState(false)

    const { state } = useLocation();

    const schema = yup.object().shape({
        email: yup.string().email('مقدار وارد شده بایستی ایمیل معتبر باشد').required('وارد کردن مقدار الزامیست'),
        password: yup.string().required('وارد کردن مقدار الزامیست'),
    });

    const { control, register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: 'faro_farzan@yahoo.com',
            password: '123123'
        }
    });



    const onSubmit = async (data) => {
        setLogging(true)
        Axios.post('/login', JSON.stringify(data))
        .then(async (res) => {
            let data = res.data
            let userInfo = {
                id: data.data.id,
                name: data.data.name,
                lastName: data.data.last_name,
                email: data.data.email,
                avatar: data.data.avatar,
                token: data.data.token
            }
            setIsSubmitted(true);
            Axios.defaults.headers.Authorization = 'Bearer ' + userInfo.token
            props.dispatch({ type: 'LOGIN', payload: userInfo });
        })
        .catch((err) => {
            AxiosErrorHandling(err)
            setServerErrors(err.response.data.data)
            setLogging(false)
        })
    };


    useEffect(() => {
        document.title = dictionary.base.title + ' - Login';
        if(localStorage.getItem('token')) {
            let token = localStorage.getItem('token')
            if(token !== null && token !== undefined) {
                Axios.post('/check-token', JSON.stringify({token: token}))
                .then(async (res) => {
                    let data = res.data
                    let userInfo = {
                        id: data.data.id,
                        name: data.data.name,
                        lastName: data.data.last_name,
                        email: data.data.email,
                        avatar: data.data.avatar,
                        token: token
                    }
                    // setIsSubmitted(true);
                    // Axios.defaults.headers.Authorization = 'Bearer ' + userInfo.token
                    store.dispatch({ type: 'LOGIN', payload: userInfo });
                    setIsSubmitted(true)
                })
                .catch((err) => {
                    if(parseInt(err.response.status) === 401) {
                        localStorage.removeItem('token')
                    } else if(parseInt(err.response.status) === 500) {
                        setHasError(true)
                        setTarget('/500')
                    } else {
                        setHasError(true)
                        setTarget('/404')
                    }
                })
            }
        }
    }, [])

    if(hasError)
        return <Navigate to={target} replace />

    return (
        <>
            {
                isSubmitted ? 
                <Navigate to={(state !== null && state?.pathname !== '/') ? state?.pathname : "/dashboard"} replace />
                :
                <div className="wrapper">
                    <div className="section-authentication-cover">
                        <div className="">
                            <div className="row g-0">

                                <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex">

                                    <div className="card shadow-none bg-transparent shadow-none rounded-0 mb-0" style={{border:'0px'}}>
                                        <div className="card-body">
                                            <img src={logo} className="img-fluid auth-img-cover-login" width="650" alt=""/>
                                        </div>
                                    </div>
                                    
                                </div>

                                <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center">
                                    <div className="card rounded-0 m-3 shadow-none bg-transparent mb-0" style={{border:'0px'}}>
                                        <div className="card-body p-sm-5">
                                            <div className="">
                                                <div className="mb-3 text-center">
                                                    <img src={logoIcon} width="60" alt="" />
                                                </div>
                                                <div className="text-center mb-4">
                                                    <h5 className="">Green Apps - WCP</h5>
                                                    <p className="mb-0" style={{fontFamily:'Vazir'}}>فرم ورود به حساب کاربری</p>
                                                </div>
                                                <div className="form-body">
                                                    {
                                                        ( serverErrors !== null && serverErrors !== undefined ) && 
                                                        <Alert variant="danger">
                                                            <h5 className="text-danger">خطا!</h5>
                                                            {
                                                                serverErrors?.map((item,index) => {
                                                                    return (<p key={index}>{item}</p>)
                                                                })
                                                            }
                                                        </Alert>
                                                    }
                                                    <Form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>ایمیل</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="email"
                                                                render={({ field, onChange }) => (
                                                                    <Form.Control 
                                                                        type="text"
                                                                        onChange={(e) => { field.onChange(e) }}
                                                                        value={field.value ? field.value : ''}
                                                                        className="form-control"
                                                                        isInvalid={errors['email']?.message}
                                                                        dir="ltr"
                                                                    />
                                                                )}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors['email']?.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                                            <Form.Label>رمز ورود</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="password"
                                                                render={({ field, onChange }) => (
                                                                    <Form.Control 
                                                                        type="password"
                                                                        onChange={(e) => { field.onChange(e) }}
                                                                        value={field.value ? field.value : ''}
                                                                        className="form-control"
                                                                        isInvalid={errors['password']?.message}
                                                                        dir="ltr"
                                                                    />
                                                                )}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors['password']?.message}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>

                                                        <a className="link" href="forget-password.html">بازنشانی رمز ورود</a>
                                                        <div className="form-group mb-0">
                                                            <Button type="submit" className="btn btn-primary btn-block w-100"
                                                                disabled={logging}
                                                            >
                                                                {
                                                                    logging ? 'در حال اعتبارسنجی ...' : 'ورود'
                                                                }
                                                            </Button>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
            
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        number: state.number,
        user: state.user
    };
}

export default connect(mapStateToProps)(Login);