import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import store from './../../store/store'
import * as IconsBi from "react-icons/bi";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Select from 'react-select';
import { Form } from 'react-bootstrap'
import { Axios, AxiosBaseURL, AxiosErrorHandling, AxiosConfigFileUpload } from "./../../connection/Axios"

function Header(props) {
    const state = store.getState()
	const navigate = useNavigate();
	const location = useLocation()
	const [lang,setLang] = useState()
	const [languages,setLanguages] = useState()

	// console.log('Header - location:',location)
    
    const callParentSidebarToggle = () => {
        props.sidebarToggle()
    }

	useEffect(() => {
		Axios.get('/languages/list')
        .then(async (res) => {
            setLanguages(res.data.data)
        })
        .catch((err) => {
            // console.log('err:',err)
        })
        .finally(() => {
            store.dispatch({ type: 'STOP_LOADING' });
        })
    }, [])

	useEffect(() => {
		setLang(state.lang)
    }, [languages])


	const title = <>
		<img src={state.user?.avatar} className="user-img" alt="user avatar" />
		<div className="user-info">
			<p className="user-name mb-0">
				{ state.user?.name + " " + state.user?.lastName }
			</p>
			<p className="designattion mb-0">
				{ state.user?.email }
			</p>
		</div>
	</>

	const handleLanguage = (lang) => {
		setLang(lang)
		store.dispatch({ type: 'SET_LANG', payload: {lang:lang} });
		console.log('HERE*****',location.pathname)
		props.updateStateForReRender()
		// window.location.reload(true)
		// navigate(location.pathname)

        Axios.defaults.baseURL = AxiosBaseURL + lang + '/'
	}

	// useEffect(() => {
    //     const x = "http://localhost/WebControlPanel/Backend/public/api/"
    //     Axios.defaults.baseURL = x + state.lang + '/'
    // }, [state.lang])

    return (
        <header>
			<div className="topbar d-flex align-items-center">
				<nav className="navbar navbar-expand gap-3">
                    {
                        !props.sidebar &&
                        <div style={{fontSize: '22px', cursor: 'pointer', color: '#008cff', marginLeft:'10px'}} onClick={callParentSidebarToggle}>
                        <IconsBi.BiMenu />
                        </div>
                    }
					<div className="mobile-toggle-menu" onClick={callParentSidebarToggle}>
                        <IconsBi.BiMenu />
					</div>

                    <div className="position-relative search-bar d-lg-block d-none" data-bs-toggle="modal" data-bs-target="#SearchModal">
						<div className="d-flex">
							<div className="me-3">
								<Form.Label style={{lineHeight:'40px',margin:'0',fontWeight:'bold'}}>زبان محتوا:</Form.Label>
							</div>
							<div className="" style={{width:'120px'}}>
								<Select
									value={languages?.find(item => item.value === lang)}
									onChange={e => handleLanguage(e.value)}
									options={languages}
									placeholder="..."
									dir="ltr"
								/>
							</div>
						</div>
                    </div>

                    <div className="top-menu ms-auto">
						{/* <Select
							// value={languages?.find(item => item.value === field.value)}
							// onChange={e => field.onChange(e?.value)}
							options={languages}
							placeholder="..."
							dir="ltr"
						/> */}
					</div>

					<div className="user-box px-3">
						<Navbar className="d-flex1 align-items-center nav-link dropdown-toggle">
							<Container>
								{/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
								<Navbar.Collapse id="basic-navbar-nav">
								<Nav>
									<NavDropdown title={title} id="basic-nav-dropdown">
										<NavDropdown.Item href="/profile">
											<IconsBi.BiUser />
											{ " " }
											<span>پروفایل کاربری</span>
										</NavDropdown.Item>
										<NavDropdown.Divider />
										<NavDropdown.Item href="/logout">
											<IconsBi.BiExit />
											{ " " }
											<span>خروج</span>
										</NavDropdown.Item>
									</NavDropdown>
								</Nav>
								</Navbar.Collapse>
							</Container>
						</Navbar>

						
						{/* <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							<img src="assets/images/avatars/avatar-2.png" className="user-img" alt="user avatar" />
							<div className="user-info">
								<p className="user-name mb-0">Pauline Seitz</p>
								<p className="designattion mb-0">Web Designer</p>
							</div>
						</a>
						<ul className="dropdown-menu dropdown-menu-end">
							<li><a className="dropdown-item d-flex align-items-center" href="#"><i className="bx bx-user fs-5"></i><span>Profile</span></a>
							</li>
							<li><a className="dropdown-item d-flex align-items-center" href="#"><i className="bx bx-cog fs-5"></i><span>Settings</span></a>
							</li>
							<li><a className="dropdown-item d-flex align-items-center" href="#"><i className="bx bx-home-circle fs-5"></i><span>Dashboard</span></a>
							</li>
							<li><a className="dropdown-item d-flex align-items-center" href="#"><i className="bx bx-dollar-circle fs-5"></i><span>Earnings</span></a>
							</li>
							<li><a className="dropdown-item d-flex align-items-center" href="#"><i className="bx bx-download fs-5"></i><span>Downloads</span></a>
							</li>
							<li>
								<div className="dropdown-divider mb-0"></div>
							</li>
							<li><a className="dropdown-item d-flex align-items-center" href="#"><i className="bx bx-log-out-circle"></i><span>Logout</span></a>
							</li>
						</ul> */}
					</div>
				</nav>
			</div>
		</header>
    )
}

// export default Header;

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(Header);