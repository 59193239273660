import React, { lazy, Suspense, useState, useEffect } from 'react';
import { useNavigate } from 'react-router'
import { BrowserRouter, Navigate, useLocation, Routes, Route, Link } from 'react-router-dom'
import { Provider } from 'react-redux';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import store from './store/store';
import Protected from './components/Protected'
import { Axios } from "./connection/Axios"
import { connect } from 'react-redux';
import Dashboard from './components/pages/dashboard/Dashboard';
// import Users_List from './components/pages/users/List';
// import Users_New from './components/pages/users/New';
import PropagateLoader from "react-spinners/PropagateLoader";


import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';

const Users_New = lazy(() => import('./components/pages/users/New'));
const Users_List = lazy(() => import('./components/pages/users/List'));
const Users_Edit = lazy(() => import('./components/pages/users/Edit'));
const Users_ResetPassword = lazy(() => import('./components/pages/users/ResetPassword'));
const Files_New = lazy(() => import('./components/pages/files/New'));
const Files_List = lazy(() => import('./components/pages/files/List'));
const Files_Edit = lazy(() => import('./components/pages/files/Edit'));
const Categories_New = lazy(() => import('./components/pages/categories/New'));
const Categories_List = lazy(() => import('./components/pages/categories/List'));
const Categories_EDIT = lazy(() => import('./components/pages/categories/Edit'));
const Articles_New = lazy(() => import('./components/pages/articles/New'));
const Articles_List = lazy(() => import('./components/pages/articles/List'));
const Articles_EDIT = lazy(() => import('./components/pages/articles/Edit'));
const Tags_New = lazy(() => import('./components/pages/tags/New'));
const Tags_List = lazy(() => import('./components/pages/tags/List'));
const Tags_Edit = lazy(() => import('./components/pages/tags/Edit'));
const Menu_Header = lazy(() => import('./components/pages/menu/Header'));
const Menu_Footer = lazy(() => import('./components/pages/menu/Footer'));
const Layout_New = lazy(() => import('./components/pages/layouts/New'));
const Layout_List = lazy(() => import('./components/pages/layouts/List'));
const Layout_Details = lazy(() => import('./components/pages/layouts/Details'));
const Layout_Edit = lazy(() => import('./components/pages/layouts/Edit'));
const Layout_Copy = lazy(() => import('./components/pages/layouts/Copy'));
const Settings_Base = lazy(() => import('./components/pages/settings/Base'));
const Settings_Slider = lazy(() => import('./components/pages/settings/Slider'));


const Articles_Test = lazy(() => import('./components/pages/articles/Test'));

function App (props) {
    const location = useLocation();
    const navigate = useNavigate();
    const state = store.getState()

    useEffect(() => {
        if(location?.pathname === '/login') {
            if(state?.user?.token) {
                navigate("/dashboard"); 
            } else if(localStorage.getItem('token')) { 
                let token = localStorage.getItem('token')
                let lang = localStorage.getItem('lang')
                if(token !== null && token !== undefined) {
                    Axios.post('/check-token', JSON.stringify({token: token}))
                    .then(async (res) => {
                        let data = res.data
                        let userInfo = {
                            id: data.data.id,
                            name: data.data.name,
                            lastName: data.data.last_name,
                            email: data.data.email,
                            avatar: data.data.avatar,
                            token: token
                        }
                        // Axios.defaults.headers.Authorization = 'Bearer ' + userInfo.token
                        // store.dispatch({ type: 'LOGIN', payload: userInfo });
                        // if(lang !== '')
                        //     store.dispatch({ type: 'SET_LANG', payload: {lang: lang} });
                        navigate("/dashboard");
                        // setLoading(false)
                    })
                    .catch((err) => {
                        // if(parseInt(err.response.status) === 500)
                        //     setTarget('/500')
                        // else
                        //     setTarget('/404')
                        // setHasError(true)
                    })
                    .finally(() => {
                        // if(lang !== '')
                        //     store.dispatch({ type: 'SET_LANG', payload: {lang: lang} });
                    })
                }
            } else {
                navigate("/login"); 
            }
        }
    }, [])

    const routes = [
        {
            id: 'login',
            path: '/login',
            element: 'ورود',
            type: 'public'
        },
        {
            id: 'dashboard',
            path: '/dashboard',
            type: 'protected',
            component: <Dashboard />
        },
        {
            id: 'settings',
            path: '/settings/base',
            type: 'protected',
            component: <Settings_Base />
        },
        {
            id: 'settings',
            path: '/settings/slider',
            type: 'protected',
            component: <Settings_Slider />
        },
        {
            id: 'users-new',
            path: '/users/new',
            type: 'protected',
            component: <Users_New />
        },
        {
            id: 'users-list',
            path: '/users/list',
            type: 'protected',
            component: <Users_List />
        },
        {
            id: 'users-edit',
            path: '/users/:id/edit',
            type: 'protected',
            component: <Users_Edit />
        },
        {
            id: 'users-edit',
            path: '/users/:id/reset-password',
            type: 'protected',
            component: <Users_ResetPassword />
        },
        {
            id: 'articles-new',
            path: '/articles/new',
            type: 'protected',
            component: <Articles_New />
        },
        {
            id: 'articles-test',
            path: '/articles/test',
            type: 'protected',
            component: <Articles_Test />
        },
        {
            id: 'articles-list',
            path: '/articles/list',
            type: 'protected',
            component: <Articles_List />
        },
        {
            id: 'articles-edit',
            path: '/articles/:id/edit',
            type: 'protected',
            component: <Articles_EDIT />
        },
        {
            id: 'files-new',
            path: '/files/new',
            type: 'protected',
            component: <Files_New />
        },
        {
            id: 'files-list',
            path: '/files/list',
            type: 'protected',
            component: <Files_List />
        },
        {
            id: 'files-list',
            path: '/files/:id/edit',
            type: 'protected',
            component: <Files_Edit />
        },
        {
            id: 'categories-new',
            path: '/categories/new',
            type: 'protected',
            component: <Categories_New />
        },
        {
            id: 'categories-list',
            path: '/categories/list',
            type: 'protected',
            component: <Categories_List />
        },
        {
            id: 'categories-edit',
            path: '/categories/:id/edit',
            type: 'protected',
            component: <Categories_EDIT />
        },
        {
            id: 'tags-new',
            path: '/tags/new',
            type: 'protected',
            component: <Tags_New />
        },
        {
            id: 'tags-list',
            path: '/tags/list',
            type: 'protected',
            component: <Tags_List />
        },
        {
            id: 'tags-edit',
            path: '/tags/:id/edit',
            type: 'protected',
            component: <Tags_Edit />
        },
        {
            id: 'menu-header',
            path: '/menu/header',
            type: 'protected',
            component: <Menu_Header />
        },
        {
            id: 'menu-footer',
            path: '/menu/footer',
            type: 'protected',
            component: <Menu_Footer />
        },
        {
            id: 'layouts-new',
            path: '/layouts/new',
            type: 'protected',
            component: <Layout_New />
        },
        {
            id: 'layouts-list',
            path: '/layouts/list',
            type: 'protected',
            component: <Layout_List />
        },
        {
            id: 'layouts-details',
            path: '/layouts/:id/details',
            type: 'protected',
            component: <Layout_Details />
        },
        {
            id: 'layouts-edit',
            path: '/layouts/:id/edit',
            type: 'protected',
            component: <Layout_Edit />
        },
        {
            id: 'layouts-copy',
            path: '/layouts/:id/copy',
            type: 'protected',
            component: <Layout_Copy />
        },
    ]
    
    return (
        <Provider store={store}>
            <Suspense fallback={<PropagateLoader color="#300700" className="d-flex aligns-items-center justify-content-center" />}>
            <Routes>
                <Route path="/" element={
                    <>
                        Landing page<br/>
                        <Link to="/login" >Login</Link>
                    </>
                } />
                <Route path="/login" element={<Login redirectTo={location} />} />
                <Route path="/logout" element={<Logout />} />
                {/* <Route path="/users/list" element={<Users_List />} /> */}
                {/* <Route path="/users/list" element={<>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Users_List />
                    </Suspense>
                </>} /> */}
                
                {
                    routes.filter(route => route.type === 'protected').map(route => {
                        return (
                            <Route
                                key={route.id}
                                path={route.path}
                                element={
                                <Protected redirectTo={location} >
                                    {route.component}
                                </Protected>
                                }
                            />
                        )
                    })
                }

                <Route path="/500" element={<>500</>}></Route>
                <Route path="*" element={<>404</>}></Route>
            </Routes>
            </Suspense>
        </Provider>
    );
}

export default App;

// const mapStateToProps = (state) => {
//     return {
//         number: state.number,
//         user: state.user
//     };
// }

// export default connect(mapStateToProps)(App);