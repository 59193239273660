import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import store from './../store/store';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Menu from './sidebar/Menu';
import Footer from './footer/Footer';
import { Axios } from './../connection/Axios'
import * as IconsBi from "react-icons/bi";
import PropagateLoader from "react-spinners/PropagateLoader";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { Alert } from 'react-bootstrap';


function Protected(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const state = store.getState()
    const dispatch = useDispatch();

    const [hasError, setHasError] = useState(false)
    const [target, setTarget] = useState(false)
    const [sidebar, setSidebar] = useState(true)
    let [color, setColor] = useState("#ffffff");

    const [re_render, setRe_render] = useState(1);
    const updateStateForReRender = () => {
        setRe_render(re_render + 1)
    }

    useEffect(() => {
        // console.log('Protected - new state:', state.loading)
    }, [dispatch])

    if (props.user?.token) {
        // return props.children
    } else if (localStorage.getItem('token')) {
        let token = localStorage.getItem('token')
        if(token !== null && token !== undefined) {
            Axios.post('/check-token', JSON.stringify({token: token}))
            .then(async (res) => {
                let data = res.data
                let userInfo = {
                    id: data.data.id,
                    name: data.data.name,
                    lastName: data.data.last_name,
                    email: data.data.email,
                    avatar: data.data.avatar,
                    token: token
                }
                store.dispatch({ type: 'LOGIN', payload: userInfo });
                store.dispatch({ type: 'SET_LANG', payload: {lang: localStorage.getItem('lang')} });
            })
            .catch((err) => {
                if(parseInt(err.response.status) === 500)
                    setTarget('/500')
                else
                    setTarget('/404')
                setHasError(true)
            })
        }
    } else {
        return <Navigate to="/" replace state={props.redirectTo} />
    }

    const sidebarToggle = () => {
        setSidebar(!sidebar)
    }

    if(hasError)
        return <Navigate to={target} replace />
        
    return (
        <>
            {
                <div className={sidebar ? "wrapper" : "wrapper toggled"}>
                    <Sidebar sidebarToggle={sidebarToggle} />
                    <Header sidebar={sidebar} sidebarToggle={sidebarToggle} updateStateForReRender={updateStateForReRender} />

                    <ToastContainer
                        position="top-left"
                        autoClose={10000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={true}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />

                    <div className="page-wrapper">
                        <div className="page-content">
                            {
                                (state.lang !== 'null' && state.lang !== undefined) ?
                                // props.children
                                React.cloneElement(props.children, {re_render: re_render})
                                :
                                <Alert key="danger" variant="danger">
                                    لطفا ابتدا زبان مورد نظر برای محتوا را انتخاب کنبد.
                                </Alert>
                            }
                        </div>
                    </div>

                    <div className="overlay toggle-icon"></div>

                    <a href="#" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
                    <Footer />
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(Protected);