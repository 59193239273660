import React, { useState } from 'react'
import Menu from './Menu'
import * as IconsBi from "react-icons/bi";
import logoIcon from './../../assets/images/logo-icon.png'


function Sidebar(props) {
    const callParentSidebarToggle = () => {
        props.sidebarToggle()
    }

    return (
        <div className="sidebar-wrapper" data-simplebar="true">
			<div className="sidebar-header">
				<div>
					<img src={logoIcon} className="logo-icon" alt="logo icon" />
				</div>
				<div>
					<h4 className="logo-text">Green Apps</h4>
				</div>
				<div className="toggle-icon ms-auto" onClick={callParentSidebarToggle}>
                    <IconsBi.BiChevronsRight />
				</div>
			</div>

            <Menu />
		</div>
    )
}

export default Sidebar;