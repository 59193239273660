import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import * as IconsBi from "react-icons/bi";

function Dashboard() {
    return (
        <>
            {
                Date.now()
            }
            <Row>
                <Col md={4}>
                    <Card bg="info" text="white">
                        <Card.Body>
                            <Card.Title style={{color:'#FFF'}}>
                                <div className="d-flex mb-3">
                                    <div className="p-2">
                                        <IconsBi.BiUserCircle style={{fontSize:'45px'}} />
                                    </div>
                                    <div className="p-2 ms-3">
                                        <h2 style={{lineHeight:'50px'}}>
                                            <span className="text-light">10</span>
                                            <span className="text-light"> / </span>
                                            <span className="text-light">3</span>
                                        </h2>
                                    </div>
                                </div>
                            </Card.Title>
                            <h4 className="mb-2 text-white">تعداد کاربران</h4>
                            <p className="mb-2 text-white">فعال / غیر فعال</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card bg="primary" text="white">
                        <Card.Body>
                            <Card.Title style={{color:'#FFF'}}>
                                <div className="d-flex mb-3">
                                    <div className="p-2">
                                        <IconsBi.BiCategoryAlt style={{fontSize:'45px'}} />
                                    </div>
                                    <div className="p-2 ms-3">
                                        <h2 style={{lineHeight:'50px'}}>
                                            <span className="text-light">10</span>
                                            <span className="text-light"> / </span>
                                            <span className="text-light">3</span>
                                        </h2>
                                    </div>
                                </div>
                            </Card.Title>
                            <h4 className="mb-2 text-white">تعداد دسته ها</h4>
                            <p className="mb-2 text-white">فعال / غیر فعال</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card bg="success" text="white">
                        <Card.Body>
                            <Card.Title style={{color:'#FFF'}}>
                                <div className="d-flex mb-3">
                                    <div className="p-2">
                                        <IconsBi.BiNews style={{fontSize:'45px'}} />
                                    </div>
                                    <div className="p-2 ms-3">
                                        <h2 style={{lineHeight:'50px'}}>
                                            <span className="text-light">10</span>
                                            <span className="text-light"> / </span>
                                            <span className="text-light">3</span>
                                        </h2>
                                    </div>
                                </div>
                            </Card.Title>
                            <h4 className="mb-2 text-white">تعداد مطالب</h4>
                            <p className="mb-2 text-white">فعال / غیر فعال</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card bg="danger" text="white">
                        <Card.Body>
                            <Card.Title style={{color:'#FFF'}}>
                                <div className="d-flex mb-3">
                                    <div className="p-2">
                                        <IconsBi.BiTagAlt style={{fontSize:'45px'}} />
                                    </div>
                                    <div className="p-2 ms-3">
                                        <h2 style={{lineHeight:'50px'}}>
                                            <span className="text-light">10</span>
                                            <span className="text-light"> / </span>
                                            <span className="text-light">3</span>
                                        </h2>
                                    </div>
                                </div>
                            </Card.Title>
                            <h4 className="mb-2 text-white">تعداد تگ ها</h4>
                            <p className="mb-2 text-white">فعال / غیر فعال</p>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card bg="secondary" text="white">
                        <Card.Body>
                            <Card.Title style={{color:'#FFF'}}>
                                <div className="d-flex mb-3">
                                    <div className="p-2">
                                        <IconsBi.BiArchive style={{fontSize:'45px'}} />
                                    </div>
                                    <div className="p-2 ms-3">
                                        <h2 style={{lineHeight:'50px'}}>
                                            <span className="text-light">10</span>
                                            <span className="text-light"> / </span>
                                            <span className="text-light">3</span>
                                        </h2>
                                    </div>
                                </div>
                            </Card.Title>
                            <h4 className="mb-2 text-white">تعداد فایلها</h4>
                            <p className="mb-2 text-white">فعال / غیر فعال</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Dashboard;