import { LOGIN, LOGOUT, START_LOADING, STOP_LOADING, SET_LANG } from './actions';

const initialState = {
    loading: true,
    logedIn: false,
    lang: 'en',
    user: {
        id: null,
        name: null,
        lastName: null,
        email: null,
        avatar: null,
        token: null,
    }
};

function reducer(state = initialState, action) {
    switch(action.type) {
        case LOGIN:
            localStorage.setItem('token',action.payload.token)
            return {
                ...state,
                logedIn: true,
                user: {
                    id: action.payload.id,
                    name: action.payload.name,
                    lastName: action.payload.lastName,
                    email: action.payload.email,
                    avatar: action.payload.avatar,
                    token: action.payload.token,
                }
            };
        case LOGOUT:
            localStorage.removeItem('token')
            return {};
        case START_LOADING:
            return {
                ...state,
                loading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                loading: false
            };
        case SET_LANG:
            localStorage.setItem('lang',action.payload.lang)
            return {
                ...state,
                lang: action.payload.lang
            };
        default:
            return state;
    }
}

export default reducer;