import axios from "axios";

const lang = localStorage.getItem('lang')

// export const AxiosBaseURL = "http://localhost/WebControlPanel/Backend/public/api/"
export const AxiosBaseURL = "https://kiakampharmed.com/api/"
// export const AxiosBaseURL = "https://kiakam.greenapps.ir/api/"

export const Axios = axios.create({
    // baseURL: "http://localhost/WebControlPanel/Backend/public/api/",
    baseURL: AxiosBaseURL + lang,
    // baseURL: "http://192.168.1.4/WebControlPanel/Backend/public/api/",
    timeout: 30000,
    headers: { 
        'Accept': 'application/json',
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin':'*',
        'Authorization': localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : '',
    }
})

// export default Axios;


// export function AxiosErrorHandling(error, setServerErrors, flashMessageData, setFlashMessageData, setAccessDenied) {
export function AxiosErrorHandling(error) {
    console.log('error:',error)
    // console.log('error:',error.response)
    // console.log('error:',error.response.data)
    // console.log('error:',error.response.data.message)

   
    
    // if(error.response.status === 500) {
    //     let message = ''//dictionary.message500
    //     if(error?.response?.message !== undefined && error?.response?.message !== '')
    //         message = message + '\n' + error.response.message

    //     setFlashMessageData([...flashMessageData, {message:message,messageStatus:'danger', duration: 10}])
    // }
    // if(error.response.status === 401)
    //     setAccessDenied(true)
    // else {
    //     if(error.response.data?.displayType === 'external')
    //         setAccessDenied(true)
    //     else {
    //         setServerErrors(error.response.data)                    
    //     }
    // }
    // console.log('AxiosErrorHandling - start')
    // console.error({ error });
    if (error.response) {
        // console.log('case 1')
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.data.status);
        // console.log(error.response.headers);
    } else if (error.request) {
        // console.log('case 2')
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
    } else {
        // console.log('case 3')
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
    }
    // console.log('AxiosErrorHandling - end')
}

export const AxiosConfigFileUpload = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}