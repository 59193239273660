import { Badge } from 'react-bootstrap'
import * as IconsBi from "react-icons/bi";

export const dictionary = {
    'base': {
        'title': 'GreenApps :: Website Control Panel'
    }
}

export const threePoint = <span className="px-3">...</span>

export const requiredIndicator = <span className="text-danger">*</span>

export const selectInvalidStyles = {
    control: base => ({
        ...base,
        fontSize: "12px",
        borderRadius: 0,
        // height: 38,
        minHeight: 38,
        boxShadow: 'none',
        borderColor: 'red'
    }),
    option: base => ({
        ...base,
        fontSize: '12px'
    })
};


export const Status = []
Status[-1] = <Badge bg="secondary" className="badge bg-secondary p-2 rounded-1" >حذف شده</Badge>
Status[0] = <Badge bg="secondary" className="badge bg-warning p-2 rounded-1" >غیر فعال</Badge>
Status[1] = <Badge bg="secondary" className="badge bg-success p-2 rounded-1" >فعال</Badge>

export const CategoryTypes = []
CategoryTypes['single'] = <IconsBi.BiFile className="text-info" style={{fontSize:'22px'}} />
CategoryTypes['multiple'] = <IconsBi.BiCollection className="text-info" style={{fontSize:'22px'}} />
CategoryTypes['link'] = <IconsBi.BiLink className="text-info" style={{fontSize:'22px'}} />

export const TagTypes = []
TagTypes['file'] = <Badge bg="primary" className="badge bg-primary p-2 rounded-1" >فایل</Badge>
TagTypes['article'] = <Badge bg="info" className="badge bg-info p-2 rounded-1" >مطلب</Badge>

export const statusTypes = [
    { value: 1, label: 'فعال/قابل مشاهده' },
    { value: 0, label: 'غیر فعال/غیر قابل مشاهده' },
    { value: -1, label: 'حذف' },
]

export const TinyAPIKey = '12yuxmbympwpaejmxti8igq7lu4bw6pqkomp1mxhy61onlio'

export const Tiny_plugins = [
    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
]

export const Tiny_toolbar = 'code | undo redo | blocks | ' +
'bold italic forecolor | alignleft aligncenter ' +
'alignright alignjustify | bullist numlist outdent indent | ' +
'removeformat | help'
