import React, { useState, useEffect } from "react";
import store from '../../store/store';
import { connect } from 'react-redux';
import Axios from "../../connection/Axios"
import { Navigate } from 'react-router-dom'

function Logout(props) {
    useEffect(() => {
        store.dispatch({ type: 'LOGOUT' });

        // I can use axios for submit log out in DB
    }, [])

    return <Navigate to="/" replace />
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(Logout);